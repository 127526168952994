.FourOhFour {
  width: 100%;
  height: 100vh;
  padding: 40px 10px 0 10px;
  margin: auto;
  box-sizing: border-box;
  max-width: 1440px;
}

.AnimationContainer {
  left: 0;
  bottom: 10%;
  position: absolute;
  width: 100vw;
  height: calc(100vw * 0.2292);
  z-index: 1;
}

.HeaderLogo {
  height: 20px;
  margin-bottom: 30px;
}

.Divider {
  height: 3px;
  border-radius: 2px;
  background-color: #444444;
  margin-bottom: 20px;
  max-width: 40px;
}

.FourOhFour h1 {
  margin: 0;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 20px;
}

.MessageContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Message {
  max-width: none;
}

.Message h2 {
  margin: 0;
  color: #f1f1f1;
  font-size: 20px;
  font-weight: 300;
  line-height: 1.33;
  margin-bottom: 20px;
}

.Destinations {
  display: flex;
  flex-direction: column;
  max-width: 380px;
  margin-top: 20px;
}

.Destinations a {
  text-decoration: none;
}

.Destination {
  min-height: none;
  display: flex;
  padding: 5px 0px;
  border-radius: 15px;
  cursor: pointer;
  box-sizing: content-box;
  margin-bottom: 5px;
}

.Destination h3 {
  margin: 0;
  color: #f1f1f1;
  font-size: 16px;
  font-weight: 500;
  margin-top: 2px;
  margin-bottom: 5px;
}

.Destinations p {
  margin: 0;
  color: #888888;
  font-size: 13px;
  font-weight: 300;
  line-height: 1.4;
  display: none;
}

.Thumbnail {
  width: 65px;
  height: 65px;
  flex: 0 0 auto;
  margin-right: 15px;
  border-radius: 10px;
  display: none;
}

.Prompt {
  font-size: 16px;
  margin: 0;
}

.Destination:first-child h3::before {
  content: '🚀 ';
}

.Destination:nth-child(2) h3::before {
  content: '📋 ';
}

.Destination:last-child h3::before {
  content: '👀 ';
}

@media screen and (min-height: 650px) {
  .Destination {
    min-height: 60px;
  }

  .Thumbnail,
  .Destination p {
    display: block;
  }

  .Destination:first-child h3::before,
  .Destination:nth-child(2) h3::before,
  .Destination:last-child h3::before {
    content: '';
  }
}

@media screen and (min-width: 800px) {
  .FourOhFour {
    padding: 6vh 6vw 0 6vw;
  }

  .HeaderLogo {
    height: 30px;
    margin-bottom: 40px;
  }

  .Divider {
    margin-bottom: 40px;
  }

  .MessageContainer {
    flex-direction: row;
    justify-content: space-between;
  }

  .Message {
    max-width: 300px;
  }

  .Prompt::after {
    content: '👉';
  }

  .Destinations {
    margin-top: 0;
  }

  .Destination {
    padding: 10px;
  }

  .Destination:hover {
    background-color: #181818;
  }
}

@media screen and (min-width: 960px) {
  .FourOhFour {
    padding: 6vh 8vw 0 8vw;
  }

  .FourOhFour h1 {
    font-size: 60px;
    margin-bottom: 40px;
  }

  .Message {
    max-width: 360px;
  }

  .Message h2 {
    font-size: 30px;
    margin-bottom: 30px;
  }

  .Prompt {
    font-size: 20px;
  }

  .Destinations p {
    font-size: 14px;
  }
}
